import { FileNode } from '../types/file-node';

export function mapFiles(
    files: FileNode[],
    prefix: string
): Record<string, string> {
    const removePathPrefix = (e: string) => e.replace(prefix, '');

    return files.reduce(
        (prev, curr) => ({
            ...prev,
            [removePathPrefix(curr.relativePath)]: removePathPrefix(
                curr.publicURL
            ),
        }),
        {} as Record<string, string>
    );
}
