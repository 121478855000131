import { css } from '@emotion/react';
import React from 'react';

import catImage0 from '../../images/cats/cat1.gif';
import catImage1 from '../../images/cats/cat2.gif';
import catImage2 from '../../images/cats/cat3.gif';
import catImage3 from '../../images/cats/cat4.gif';
import catImage4 from '../../images/cats/cat5.gif';

const catImages = [catImage0, catImage1, catImage2, catImage3, catImage4];

export const Cat: React.VFC<{ className?: string; randomValue?: number }> = ({
    className,
    randomValue,
}) => {
    const randomCatImage =
        catImages[
            Math.floor((randomValue ?? Math.random()) * catImages.length)
        ];

    return (
        <div
            css={css`
                transition: 0.2s transform;
                :hover {
                    transform: scale(1.1);
                }

                animation: appear 1s ease;
                @keyframes appear {
                    0% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 100;
                    }
                }
            `}
            className={className}
        >
            <img
                src={randomCatImage}
                alt="cat"
                css={css`
                    /* animation: rolling
                        ${5 + (randomValue ?? Math.random()) * 10}s linear;
                    animation-iteration-count: infinite; */
                    padding: 10px;
                    transition: 0.2s;
                    width: 100px;

                    @keyframes rolling {
                        0% {
                            transform: rotateZ(0deg);
                        }

                        100% {
                            transform: rotateZ(360deg);
                        }
                    }
                `}
            />
        </div>
    );
};
